.mobileNav {
  transition: all 300ms linear;
}

.expand {
  transform: translateX(0);
}

.collapse {
  transform: translate(-100%);
}

.mobileNavHeader {
  height: 70px;
}
